// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../node_modules/vue-loader/dist/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../node_modules/primevue/resources/primevue.min.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../node_modules/vue-loader/dist/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../node_modules/primevue/resources/themes/saga-blue/theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-wrapper{animation:show-sidebar .3s ease-in-out normal;position:fixed;top:0;left:0;height:100%;width:20rem;background:#fff;z-index:1000;transform:translateX(-100%);transition:transform .3s;padding-bottom:4rem!important;display:block!important}.menu-wrapper.collapsed{width:5rem}.menu-wrapper.open{transform:translateX(0)}@keyframes show-sidebar{0%{transform:translateX(-100%)}to{transform:translateX(0)}}@media(max-width:768px){.menu-wrapper{width:100%}}.layout-menu-light .menu-wrapper.collapsed .layout-menu-container .layout-menu .layout-root-menuitem ul span,.layout-menu-light .menu-wrapper.collapsed .layout-menu-container .layout-menu>li>div,.menu-wrapper.collapsed .layout-topbar-left a,.menu-wrapper.collapsed .layout-topbar-left h6{display:none!important}.menu-wrapper.collapsed .layout-topbar-left button{transform:rotate(180deg)}.layout-menu-light .menu-wrapper.collapsed .layout-menu-container .layout-menu .layout-root-menuitem ul li ul li a span{display:none!important}.layout-menu-light .menu-wrapper.collapsed .layout-menu-container .layout-menu .layout-root-menuitem ul li ul li a{margin-left:0;padding-left:10px}.menu-wrapper.collapsed .pi-angle-down.layout-submenu-toggler{display:none!important}.layout-menu-light .menu-wrapper.collapsed .layout-menu-container .layout-menu>li>ul li a i{font-size:23px!important}.menu-wrapper.collapsed .layout-menu-container .layout-menu{margin:-5px!important}#main-trustech{margin-top:5.7rem;margin-left:22rem;transition:margin-left .3s}@media(max-width:768px){#main-trustech{margin-top:8rem;margin-left:1.5rem}}.menu-wrapper.collapsed+#main-trustech{margin-left:6rem}.layout-topbar-light .layout-topbar{height:48px!important}#footerDin{margin-left:17rem;transition:margin-left .3s}@media(max-width:768px){#footerDin{margin-left:0}}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
