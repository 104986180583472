import "core-js/modules/es.array.push.js";
import AppSubmenu from "./AppSubmenu.vue";
import { useRouter } from "vue-router";
import { useGlobalStore } from "@/stores/global-store.ts";
import VTooltip from "v-tooltip";
export default {
  directives: {
    tooltip: VTooltip
  },
  name: "AppMenu",
  props: {
    menuMode: String,
    active: Boolean,
    mobileMenuActive: Boolean,
    toggleSidebar: Function,
    logo: {
      type: String,
      "default": require("../../public/layout/images/modules/logo-trustechUX.png")
    }
  },
  data: function data() {
    return {
      version: "1.0.0",
      model: [{
        //label: 'Favorites',
        label: "Privacidade",
        icon: "",
        //items: [{ label: 'Dashboard', icon: 'home', to: '/' }],
        items: [{
          label: "Mapeamento de Dados",
          icon: "document_scanner",
          items: [{
            label: "Inventários",
            icon: "inventory",
            items: [{
              label: "Atividades",
              icon: "monitor_heart",
              to: "/modules/mapeamento-de-dados/#/home"
            }]
          }, {
            label: "Configurações",
            icon: "settings",
            to: "/modules/mapeamento-de-dados/#/config"
          }, {
            label: "Instruções de uso",
            icon: "book",
            to: "/modules/mapeamento-de-dados/#/instructions-for-use"
          }]
        }, {
          label: "Solicitações de Titulares",
          icon: "contact_page",
          items: [{
            label: "Registros de solicitações",
            icon: "folder_open",
            to: "/modules/solicitation-form/solicitations"
          }, {
            label: "Link para Portal",
            icon: "link",
            to: "/form-request/FormRequest/df08615b-6fbe-431f-a1f1-83cc0a0d4ed"
          }
          // {
          //   label: "Dashboards",
          //   icon: "explore",
          //   to: "modules/mapeamento-de-dados#/access-denied-m",
          // },
          // {
          //   label: "Relatórios",
          //   icon: "summarize",
          //   to: "modules/mapeamento-de-dados#/access-denied-m",
          // },
          ]
        }
        // {
        //   label: "Cookies (v2)",
        //   icon: "cookie",
        //   to: "",
        // },
        ]
      }, {
        //label: 'UI Kit',
        label: "GRC",
        icon: "",
        items: [
        /*{ label: 'Form Layout', icon: 'id-card', to: '/formlayout' },
                    { label: 'Input', icon: 'check-square', to: '/input' },
                    { label: 'Float Label', icon: 'bookmark', to: '/floatlabel' },
                    { label: 'Invalid State', icon: 'exclamation-circle', to: '/invalidstate' },
                    { label: 'Button', icon: 'mobile', to: '/button', class: 'rotated-icon' },
                    { label: 'Table', icon: 'table', to: '/table' },
                    { label: 'List', icon: 'list', to: '/list' },
                    { label: 'Tree', icon: 'share-alt', to: '/tree' },
                    { label: 'Panel', icon: 'tablet', to: '/panel' },
                    { label: 'Overlay', icon: 'clone', to: '/overlay' },
                    { label: 'Media', icon: 'image', to: '/media' },
                    { label: 'Menu', icon: 'bars', to: '/menu' },
                    { label: 'Message', icon: 'comment', to: '/messages' },
                    { label: 'File', icon: 'file', to: '/file' },
                    { label: 'Chart', icon: 'chart-bar', to: '/chart' },
                    { label: 'Misc', icon: 'circle', to: '/misc' },*/
        {
          label: "Área de Licenças",
          icon: "inventory",
          to: "/modules/license-area#/home"
        }, {
          label: "Due Dilligence de Terceiros",
          icon: "account_tree",
          items: [{
            label: "Due Dilligence",
            icon: "folder_open",
            to: "/modules/due-diligence#/requests/home"
          }, {
            label: "Configurações",
            icon: "explore",
            to: "/modules/due-diligence#/settings"
          }, {
            label: "Instruções de Uso",
            icon: "book",
            to: "/modules/due-diligence#/instructions-for-use"
          }]
        }, {
          label: "Gestão de Riscos",
          icon: "warning",
          items: [{
            label: "Registros de riscos",
            icon: "folder_open",
            to: "/modules/gestao-de-riscos#/home"
          }, {
            label: "Configurações",
            icon: "explore",
            to: "/modules/gestao-de-riscos#/config"
          }, {
            label: "Instruções de Uso",
            icon: "book",
            to: "/modules/gestao-de-riscos#/instructions-for-use"
          }
          // {
          //   label: "Relatórios",
          //   icon: "summarize",
          //   to: "modules/mapeamento-de-dados#/access-denied-m",
          // },
          // {
          //   label: "Seguros",
          //   icon: "health_and_safety",
          //   to: "modules/mapeamento-de-dados#/access-denied-m",
          // },
          ]
        }, {
          label: "Gestão de Requisitos Legais",
          icon: "rule",
          items: [{
            label: "Inventários",
            icon: "inventory",
            to: "modules/mapeamento-de-dados#/access-denied-m",
            items: [{
              label: "Cadastro de requisitos",
              icon: "list",
              to: "modules/mapeamento-de-dados#/access-denied-m"
            }, {
              label: "Cadastro de licenças",
              icon: "list",
              to: "modules/mapeamento-de-dados#/access-denied-m"
            }]
          }, {
            label: "Monitoramento legal",
            icon: "policy",
            to: "modules/mapeamento-de-dados#/access-denied-m"
          }, {
            label: "Dashboards",
            icon: "explore",
            to: "modules/mapeamento-de-dados#/access-denied-m"
          }, {
            label: "Relatórios",
            icon: "summarize",
            to: "modules/mapeamento-de-dados#/access-denied-m"
          }, {
            label: "Gestor de atributos",
            icon: "assignment",
            to: "modules/mapeamento-de-dados#/access-denied-m"
          }]
        }, {
          label: "Jurídico Preventivo",
          icon: "policy",
          items: [{
            label: "Registro de chamados",
            icon: "list",
            to: "/modules/consultivo#/"
          }, {
            label: "Registro de chamados antigos",
            icon: "list",
            to: "/modules/consultivo#/old-requests"
          }, {
            label: "Configurações",
            icon: "dashboard",
            to: "/modules/consultivo#/dashboard"
          }, {
            label: "Instruções de uso",
            icon: "book",
            to: "/modules/consultivo#/instructions-for-use"
          }
          // {
          //   label: "Cadastros",
          //   icon: "app_registration",
          //   items: [
          //     /*{
          //       label: "Área",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/area",
          //     },
          //     {
          //       label: "Subarea",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/subarea",
          //     },*/
          //     {
          //       label: "Tipo de Motivo",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/reasonType",
          //     },
          //     {
          //       label: "Ocorrência",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/event",
          //     },
          //     {
          //       label: "Fundamento Legal",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/legalBasis",
          //     },
          //     {
          //       label: "Loja",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/store",
          //     },
          //     {
          //       label: "Usuários",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/user",
          //     },
          //     /*{
          //       label: "Ações disciplinares",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/disciplinaryAction",
          //     },*/
          //     {
          //       label: "Encerramento",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/closure",
          //     },
          //     {
          //       label: "Reprovação",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/reprovacao",
          //     },
          //     {
          //       label: "Evidência",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/evidence",
          //     },
          //     /*{
          //       label: "Colaborador",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/colaborator",
          //     },*/
          //     {
          //       label: "SLA",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/sla",
          //     },
          //     /*{
          //       label: "Hierarquia",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/hierarchy",
          //     },*/
          //     {
          //       label: "Respostas automáticas",
          //       icon: "list",
          //       to: "/modules/consultivo#/cruds/automatedAnswer",
          //     },
          //   ],
          // },
          ]
        }, {
          label: "Prevenção de Perdas Patrimoniais",
          icon: "account_balance",
          items: [{
            label: "Registro de solicitações",
            icon: "list",
            to: "/modules/prevencao-de-perdas#/requests/home"
          }, {
            label: "Configurações",
            icon: "dashboard",
            to: "/modules/prevencao-de-perdas#/settings"
          }, {
            label: "Instruções de uso",
            icon: "book",
            to: "/modules/prevencao-de-perdas#/instructions-for-use"
          }]
        }]
      }, {
        //label: 'Prime Blocks',
        label: "Ferramentas Básicas",
        icon: "",
        items: [
        /*
          { label: 'Free Blocks', icon: 'eye', to: '/blocks' },
          { label: 'All Blocks', icon: 'globe', url: 'https://www.primefaces.org/primeblocks-vue', target: '_blank' },
        */
        {
          label: "Avaliações",
          icon: "rate_review",
          items: [{
            label: "Registros de avaliações",
            icon: "folder_open",
            to: "modules/mapeamento-de-dados#/access-denied-m"
          }, {
            label: "Galeria de formulários",
            icon: "browse_gallery",
            to: "modules/mapeamento-de-dados#/access-denied-m"
          }]
        }, {
          label: "Planos de Ação",
          icon: "checklist",
          items: [{
            label: "Registros de ações",
            icon: "folder_open",
            to: "/modules/action-plan/ActionPlans"
          }]
        }, {
          label: "Biblioteca",
          icon: "library_books",
          items: [{
            label: "Registros de documentos",
            icon: "folder_open",
            to: "/modules/document-library"
          }, {
            label: "Galeria de modelos de documentos",
            icon: "collections_bookmark",
            to: "/cruds/modelsLibrary"
          }]
        }, {
          label: "Relatórios",
          icon: "description",
          items: [{
            label: "Relatórios criados",
            icon: "list",
            to: "/cruds/Reports"
          }]
        },
        // {
        //   label: "Dashboards",
        //   icon: "explore",
        //   items: [
        //     {
        //       label: "Relação de painéis",
        //       icon: "dashboard",
        //       //to: "modules/mapeamento-de-dados#/access-denied-m",
        //     },
        //   ],
        // },
        {
          label: "Cadastros",
          icon: "app_registration",
          items: [{
            label: "Empresa (tenant)",
            icon: "list",
            to: "/cruds/Tenant"
          }, {
            label: "Estrutura Organizacional",
            icon: "list",
            to: "/cruds/organization"
          },
          // {
          //   label: "Perfis",
          //   icon: "list",
          //   //to: "modules/mapeamento-de-dados#/access-denied-m",
          // },
          {
            label: "Usuários",
            icon: "list",
            to: "/home#/settings/modules-config/users"
          }, {
            label: "Relatórios",
            icon: "list",
            to: "/cruds/Reports"
          }, {
            label: "Grupos",
            icon: "list",
            to: "/cruds/Group"
          }, {
            label: "Categoria",
            icon: "list",
            to: "/cruds/categoria"
          }, {
            label: "SubCategoria",
            icon: "list",
            to: "/cruds/subcategoria"
          }, {
            label: "Cadastro de Dados pessoais",
            icon: "list",
            to: "/cruds/personalData"
          }, {
            label: "Controle de Medidas",
            icon: "list",
            to: "/cruds/ControlMeasures"
          }]
        }
        // {
        //   label: "Audit Log (v2)",
        //   icon: "quick_reference_all",
        //   //to: "modules/mapeamento-de-dados#/access-denied-m",
        // },
        ]
      }, {
        //label: 'Utilities'
        label: "Smart Workflow ®",
        icon: "",
        items: [
        //{ label: 'PrimeIcons', icon: 'prime', to: '/icons' },
        //{ label: 'PrimeFlex', icon: 'desktop', url: 'https://www.primefaces.org/primeflex/', target: '_blank' },
        {
          label: "Guia LGPD",
          icon: "shield",
          to: "modules/mapeamento-de-dados#/access-denied-m"
        }]
      }, {
        //label: 'Pages',
        label: "Smart Assistance ®",
        icon: "",
        items: [{
          label: "Cronograma projeto",
          icon: "today",
          to: "modules/mapeamento-de-dados#/access-denied-m"
        }, {
          label: "Serviços contratados",
          icon: "fact_check",
          to: "modules/mapeamento-de-dados#/access-denied-m"
        }, {
          label: "Orientações DPO (v2)",
          icon: "manage_accounts",
          to: "modules/mapeamento-de-dados#/access-denied-m"
        }, {
          label: "Seguros",
          icon: "health_and_safety",
          to: "modules/mapeamento-de-dados#/access-denied-m"
        }, {
          label: "Chat com a Trustech",
          icon: "comment",
          to: "modules/mapeamento-de-dados#/access-denied-m"
        }, {
          label: "Abrir chamado",
          icon: "edit",
          to: "modules/mapeamento-de-dados#/access-denied-m"
        }, {
          label: "Ajuda sobre a plataforma",
          icon: "info",
          to: "modules/mapeamento-de-dados#/access-denied-m"
        }]
      }]
    };
  },
  components: {
    AppSubmenu: AppSubmenu
  },
  emits: ["menu-click", "menuitem-click", "root-menuitem-click", "menu-button-click"],
  setup: function setup() {
    var router = useRouter();
    var globalStore = useGlobalStore();
    return {
      router: router,
      globalStore: globalStore
    };
  },
  methods: {
    onMenuItemClick: function onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    onRootMenuItemClick: function onRootMenuItemClick(event) {
      this.$emit("root-menuitem-click", event);
    },
    /*onTopbarItemClick(event, item) {
      this.$emit("topbar-item-click", { originalEvent: event, item: item });
      event.preventDefault();
    },*/
    onTopbarSubItemClick: function onTopbarSubItemClick(event) {
      event.preventDefault();
    },
    // handleSignOut: async function () {
    //   event.preventDefault();
    //   if (!(await this.signOut())) return; // erro
    //   this.router.push({ name: "login" });
    // },
    navigateToSettings: function navigateToSettings() {
      this.$router.push({
        name: "general-settings"
      });
    },
    navigateToResetPassword: function navigateToResetPassword() {
      this.$router.push({
        name: "reset-password"
      });
    },
    handleHideLeftSideMenu: function handleHideLeftSideMenu() {
      this.globalStore.toggleLeftSideMenu("overlay");
    }
  }
};