import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  // {
  //   path: "/",
  //   redirect: { name: "home" },
  // },
  // {
  //   path: "/home",
  //   name: "home",
  //   component: () => import("@/views/Home.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  next();
});

export default router;
